<template>
  <v-container v-auth-acl="'gh-perm-usuario'" fluid class="down-top-padding">
    <!--<v-container fluid class="down-top-padding">-->
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <MatrizLista v-auth-acl="'gh-perm-usuario-read'"></MatrizLista>
          <!--<MatrizLista></MatrizLista>-->
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Usuarios',
  components: {
    MatrizLista: () => import('../components/MatrizLista')
  },
  data: () => ({
    page: {
      title: 'Usuarios'
    },
    breadcrumbs: [
      {
        text: 'Autorizaciones',
        disabled: true
      },
      {
        text: 'Usuarios',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>
