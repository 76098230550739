<template>
  <v-container v-auth-acl="'gh-kan-tablero'" fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs" height="100%">
      <template v-slot:item-right>
        <div class="d-flex mr-10" style="height: 80px; font-weight: 500;">
          <div class="align-self-center mr-2" style="font-size: 26px;">
            % =
          </div>
          <div class="align-self-center text-center" style="font-size: 18px;">
            <div>Tareas en DONE</div>
            <v-divider style="border-color: black;"></v-divider>
            <div>Total de Tareas</div>
          </div>
        </div>
        <v-card tile class="grey lighten-4 align-center" style="width: 195px; height: 80px;">
          <v-card-title
            style="line-height: 1.6rem; font-size: 12px;"
            class="primary white--text py-0 px-2 d-flex justify-center"
            >% CUMPLIMIENTO</v-card-title
          >
          <v-card-text class="pb-0 py-2">
            <span :class="`font-weight-bold ${colorPorcentaje} d-flex justify-center`" style="font-size: 28px;">
              {{ porcentaje }}%
            </span>
            <span class="font-weight-black d-flex justify-center" style="font-size: 15px;">
              {{ tareasHechas }}/{{ totalTareas }}
            </span>
          </v-card-text>
        </v-card>
      </template>
    </BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <KanbanLista
            v-auth-acl="'gh-kan-tablero-read'"
            @actualizarLista="actualizarLista"
            @porcentajesCumplimiento="porcentajesCumplimiento"
          ></KanbanLista>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KanbanDashboard',
  components: {
    KanbanLista: () => import('../components/KanbanLista')
  },
  data: () => ({
    page: {
      title: 'KANBAN'
    },
    breadcrumbs: [
      {
        text: 'KANBAN',
        disabled: true
      },
      {
        text: 'TABLERO',
        disabled: false
      }
    ],
    realizados: '',
    total: '',
    listas: []
  }),
  computed: {
    colorPorcentaje() {
      if (this.porcentaje >= 90) return 'green--text text--darken-2';
      else if (this.porcentaje >= 80) return 'orange--text text--darken-2';
      else return 'red--text text--darken-2';
    },
    porcentaje() {
      let porc = 0;
      if (this.totalTareas != 0 && this.tareasHechas != 0) {
        porc = (this.tareasHechas * 100) / this.totalTareas;
        porc = Number.parseFloat(porc).toFixed(2);
      }
      return porc;
    },
    totalTareas() {
      let total = 0;
      this.listas.forEach((el) => {
        console.log(el, 'el');
        total += el.actividades.length;
      });
      return total;
    },
    tareasHechas() {
      if (this.listas.length > 0) return this.listas[this.listas.length - 1].actividades.length;
      return 0;
    }
  },
  methods: {
    porcentajesCumplimiento(porcentaje) {
      this.porcentaje = porcentaje.porcentaje;
      this.realizados = porcentaje.totalrealizados;
      this.total = porcentaje.totaltareas;
    },
    actualizarLista(listas) {
      this.listas = listas;
    }
  }
};
</script>
