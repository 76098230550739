<template>
  <!--<v-container fluid class="down-top-padding">-->
  <v-container v-auth-acl="'gh-perm-usuario'" fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <UsuarioFormulario v-auth-acl="'gh-perm-usuario-read'"></UsuarioFormulario>
          <!--<UsuarioFormulario></UsuarioFormulario>-->
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UsuarioFormNuevo',
  components: {
    UsuarioFormulario: () => import('../components/UsuarioFormulario')
  },
  data: () => ({
    title: 'REGISTRO USUARIO',
    page: {
      title: 'Registro Usuario'
    },
    breadcrumbs: [
      {
        text: 'Presupuesto',
        disabled: true
      },
      {
        text: 'Usuario',
        disabled: true
      },
      {
        text: 'Registro',
        disabled: false
      }
    ]
  }),
  methods: {},
  created() {}
};
</script>
